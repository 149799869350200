<template>
  <div>
    <el-button size="mini" @click="load">查看连接记录</el-button>
    <el-dialog append-to-body title="连接记录" :visible.sync="dialogTableVisible">
      <el-table v-loading="loading" :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="open_id" label="连接用户openid"></el-table-column>
        <el-table-column prop="about_uid" label="关联用户编号"></el-table-column>
        <el-table-column prop="about_nickname" label="关联用户昵称"></el-table-column>
        <el-table-column prop="about_avatar" label="关联用户头像">
          <template #default="s">
            <img :src="s.row.about_avatar | tomedia" style="height: 3rem;width: 3rem;border-radius: .5rem" alt="">
          </template>
        </el-table-column>
        <el-table-column label="连接时间">
          <template #default="s">
            {{s.row.created_at | date}}
          </template>
        </el-table-column>
        <el-table-column prop="award_fee" label="奖励发放"></el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "PluginWifiConnectLog",
  props:{
    CodeId:{
      type:Number,
      default:0,
    }
  },
  data(){
    return{
      dialogTableVisible:false,
      loading:false,
      list:[],
      total:0,
      page:1,
    }
  },
  methods:{
    load(){
      this.loading = true;
      this.dialogTableVisible = true;
      this.$u.api.card.plugin.wifi.connectLogSearch({
        page:this.page,
        code_id:this.CodeId,
      }).then(res=>{
        this.loading = false;
        this.list = res.list;
        this.total = res.total;
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    },
  }
}
</script>

<style scoped>

</style>